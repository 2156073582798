// Slick Slider
$(document).ready(function(){
  
  // Slider (Home)
	$('.slider-home').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
  		arrows: true,
  		infinite: true,
  		fade: true,
      adaptiveHeight: false,
  		speed: 700,
  		pauseOnHover: false,
  		dots: false,
      centerMode:true,
      centerPadding: '0px',
      appendArrows: slider.parent().find('.slider-home-arrows'),
  	});
	});  
  
  
  // Slider (Teaser-Icon)
	$('.slider-icon-teaser').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 5,
  		infinite: false,
      speed:500,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
        		infinite: false,
            speed:500,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
        		infinite: false,
            speed:500,
          }
        }
      ]
  	});
	});
  
  // Slider (NewsTeaser)
	$('.slider-news-teaser').each(function () {
  	var slider = $(this);
  	function positionSliderTeaserArrows(slider){
      var imgHeight = slider.find('.teaser-news-img').first().outerHeight();
      var positionTop = (imgHeight/2)+'px';
      slider.find('.slick-prev, .slick-next').css('top', positionTop);
      //console.log('ArrowsTop: '+positionTop);
  	}
  	slider.slick({
      slidesToShow: 3,
  		infinite: false,
      speed:500,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
        		infinite: false,
            speed:500,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
        		infinite: false,
            speed:500,
          }
        }
      ]
  	});
    slider.on('setPosition', function(e) {
      positionSliderTeaserArrows(slider);
    });
	});
  
  // Slider (Story)
	$('.slider-story').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
  		arrows: true,
  		infinite: false,
  		fade: true,
      adaptiveHeight: true,
  		speed: 500,
  		pauseOnHover: false,
      centerMode:true,
      centerPadding: '0px',
  		prevArrow: slider.find('.story-teaser-nav .slick-prev'),
  		nextArrow: slider.find('.story-teaser-nav .slick-next')
  	});
	});  
  
  
  // Slider (Karte)
	$('.slider-map-nav').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
  		infinite: false,
      speed:500,
      focusOnSelect: true,
      asNavFor: '.slider-map',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
        		slidesToScroll: 1,
            infinite: false,
            speed:500,
            focusOnSelect: true,
            asNavFor: '.slider-map',
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
        		slidesToScroll: 1,
            infinite: false,
            speed:500,
            focusOnSelect: true,
            asNavFor: '.slider-map',
          }
        }
      ]
  	});
    slider.on('setPosition', function(e) {
      var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
      var numberOfSlides = $(slider).find('.slick-slide').length;
      //console.log('slidesShown: '+slidesShown);
      //console.log('numberOfSlides: '+numberOfSlides);
      if (slidesShown === numberOfSlides) {
        $(slider).find('.slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
      }
    });
  }); 
	$('.slider-map-image').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
  		arrows: false,
      dots: false,
  		infinite: false,
  		fade: true,
      adaptiveHeight: false,
  		speed: 500,
  		//customPaging: function(slider, i) { 
  		//	var title = $(slider.$slides[i]).find('.title').text();
  		//	return '<div class="content">'+title+'</div>';
  		//},
      //appendDots: slider.parent().find('.slider-map-dots'),
      asNavFor: '.slider-map',
  	});
	});  
	$('.slider-map-text').each(function () {
  	var slider = $(this);
  	slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
  		arrows: false,
  		infinite: false,
  		fade: true,
      adaptiveHeight: true,
  		speed: 500,
      asNavFor: '.slider-map',
  	});
	});  
    
});
jQuery(document).ready(function($) {

  // When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the navbar
var navbar = document.getElementById("navbar");

// Get the offset position of the navbar
var sticky = 300;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
    $('body').removeClass('offcanvas-collapse-open');
  }
} 
		  
  //var $navOffset = $('body').data('offset');
  
	if ($(window).width() < 5000) {
		var $navOffset = 100;
	} else {
    var $navOffset = 130;
	} 
  
	// ##############################################
	// ################### To Top ###################
	// ##############################################  
  
	(function () {
  	var footer = $('body footer').get(0);
  	var top;
  	var scrollPos;
      var vh = $(window).outerHeight();
      $(window).resize(function () {
          vh = $(window).outerHeight();
      });
      var scrollToTop =  $('.scrollToTop');
  	
  	var relative = false;
  	var visible = false;
  	var stickyNav = true;
  	
    $(window).scroll(function () {
      scrollPos = $(window).scrollTop();
      top = footer.getBoundingClientRect().top;
      
      if ((top < vh) != relative) {
        relative = top < vh;
        scrollToTop.toggleClass('relative', relative);
      }
      
      if (visible != (scrollPos > 250)) {
        visible = scrollPos > 250;
        scrollToTop.fadeToggle(visible);
      }
        
    });  	
	}())
  
	$('.scrollToTop').click(   function(){
		$('html, body').animate({scrollTop : 0}, 800);
		return false;
	});

    
/*$('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {

    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && location.hostname == this.hostname
       ) {

      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

      if (target.length) {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 90
        }, 1000);
      }
    }
  });*/
  
  // ##############################################
  // ############### OffCanvas-Nav ################
  // ##############################################
    
  $('.menu-btn, .site-overlay').click(function(){
    $('body').toggleClass('offcanvas-collapse-open');
  });

  
  $('#navbarSupportedContent1').on('shown.bs.collapse', function () {
   $("body").addClass("navtoggler")
  });

  $('#navbarSupportedContent1').on('hidden.bs.collapse', function () {
   $("body").removeClass("navtoggler")
   $("body").toggleClass("offcanvas-collapse-open")
  });
	// ##############################################
	// ################ Anchor-Link #################
	// ##############################################  
  
  $(".anchor-link").click(function(event) {
    event.preventDefault();
    if ($(this).closest('.nav-overlay').length) {
      //console.log('Ist in Mobile-Nav');
      $('body').removeClass('nav-open');
    }
    if ($(this.hash).offset()) {
      $('html,body').animate( { scrollTop:($(this.hash).offset().top - ($navOffset - 1))} , 800);
    }
  });	
  
  
  // ##############################################
	// ################ Page Scroll #################
	// ##############################################

  var hash = window.location.hash;
  if (hash.length) {
    $('body, html').scrollTop(0);
    $('body').imagesLoaded(function() {
      if ($(hash).offset()) {
        var $targetTop = $(hash).offset().top;
        setTimeout(function(){
          $('html,body').animate( { scrollTop:($targetTop - ($navOffset - 1))} , 800);
        }, 1000);        
      }
    });
  }


});
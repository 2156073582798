$(function() {
    jQuery('.card-filter__list-item').click(function() {
        $('.card-filter__list-item').removeClass('active');
        $(this).addClass('active');
      // grab data-filter attr and if not * hide all items that do not have that class
      // using slideDown (all) followed by slidUp (selection) - js used for this as it's thoughts to be less work on the browsers that struggle with ispotope 
      // Fade in/out works better with columns as alide or similar created a messy animation
      var filter = this.id;
      if (filter == '*') {
        jQuery('.card-holder .card-wrapper').fadeIn(500);
      } else {
        jQuery('.card-holder .card-wrapper.' + filter).fadeIn(500);
        jQuery('.card-holder .card-wrapper:not(.' + filter + ')').fadeOut(500);
      }
    });
  });
// Basic Functions
$(document).ready(function(){
    
	// ##############################################
	// #################### forms ###################
	// ##############################################  
    
  $('input, textarea').each(function(){
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });
  
  $('input, textarea').focusin(function(){
    $(this).addClass("focus");
  });

  $('input, textarea').focusout(function(){
    $(this).removeClass("focus");
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });

  $('a[data-toggle="tablist"]').on('shown.bs.tab', function (e) {
    e.target // newly activated tab
    e.relatedTarget // previous active tab
  })
  
  
	// ##############################################
	// ################## On Screen #################
	// ##############################################  
  
	jQuery.fn.isOnScreen = function()
	{
	    var win = jQuery(window);
 
	    var viewport = {
	        top : win.scrollTop(),
	        left : win.scrollLeft()
	    };
	    viewport.right = viewport.left + win.width();
	    viewport.bottom = viewport.top + win.height();
 
	    var bounds = this.offset();
	    bounds.right = bounds.left + this.outerWidth();
	    bounds.bottom = bounds.top + this.outerHeight();
 
	    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
	};
  

  // ##############################################
	// ############## Srcoll Animation ##############
	// ##############################################
  
	var animationInit = function () {
    $('.to-be-animated').each(function() {
      if ($(this).isOnScreen() && !$(this).hasClass('animated')) {
        if ($(this).data('animation-type')) {
          var $animationType = $(this).data('animation-type');
        } else {
          // Default: fadeIn
          var $animationType = "fadeIn";
        }
        var $animationSpeedCheck = $(this).data('animation-duration');
        if ($animationSpeedCheck) {
          if ($.isNumeric($animationSpeedCheck)) {
            if ($animationSpeedCheck < 10) {
              var $animationSpeed = $animationSpeedCheck + 's';
              $(this).css('-webkit-animation-duration', $animationSpeed);
              $(this).css('-o-animation-duration', $animationSpeed);
              $(this).css('animation-duration', $animationSpeed);
            }
          }
        } 
        var $animationClass = "animated " + $animationType; 
        $(this).removeClass('to-be-animated');
        $(this).addClass($animationClass);
      }
    });
	};
  if ($('.to-be-animated').length) {
    animationInit();
  }
  
  
  // ##############################################
	// ################## Parallax ##################
	// ##############################################
  
	var parallaxInit = function () {
    $('.parallax-container').each(function() {
      if ($(this).isOnScreen()) {
        var difference = $(window).scrollTop() - $(this).offset().top;
        var parallaxImages = [];
        var parallaxImages = $(this).find('img');
        $.each( parallaxImages, function( key, value ) {
          if ($(this).isOnScreen()) {
            var calc = 4 + ((key+1)*8);
            //console.log(calc);
            var offset = (difference / calc) + 'px';
            $(this).css('transform', 'translateY('+offset+')');
          }
        });
      } 
    });
	};
  if ($('.parallax-container').length) {
    parallaxInit();
  }
  
  // ##############################################
	// ############### Trigger Srcoll ###############
	// ##############################################

  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;

  $(window).scroll(function(event){
      didScroll = true;      
  });

  setInterval(function() {
      if (didScroll) {
          hasScrolled();
          animationInit();
          parallaxInit();
          didScroll = false;
      }
  }, 10);

  function hasScrolled() {
    
    var st = $(window).scrollTop();
  
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) 
      return;
        
    lastScrollTop = st;
  }
  
  hasScrolled();

});

$("img").addClass("img-fluid");
$("figure .img-fluid").addClass("w-100");

import 'parsleyjs'
import 'parsleyjs/dist/i18n/de.js'

// @ts-ignore
$.extend<ParsleyOptions, Partial<ParsleyOptions>>(window.Parsley.options, {
  classHandler({ $element }) {
    if ($element.is('select') && $element.parent().is('.bootstrap-select')) {
      return $element.parent()
    }
    return $element
  },
  errorsContainer({ $element }) {
    return $element.closest('.form-group, .form-check')
  },
  errorClass: 'is-invalid',
  errorsWrapper: '<div class="invalid-feedback"></div>',
  errorTemplate: '<span></span>',
  successClass: 'is-valid',
  trigger: 'blur change ',
  triggerAfterFailure: 'input change',
})
